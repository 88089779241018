import {
    ALERT, GET_BLOG_CATEGORY,
    REMOVE_ALERT,
} from "../actionType";
import {
    getDataAPI,
    postDataAPI,
    deleteData,
    patchDataApi
} from "../../../services/apiWrapper";

import { toast } from "react-toastify";

export const postCategoryAdd = (obj) => async (dispatch) => {
    try {
        //   dispatch({ type: ALERT, payload: { loading: true } });
        const response = await dispatch(postDataAPI(`blogs-category`, obj));
        if (response) {
            toast.success('Category Added successfully');
            // dispatch({ type: ALERT, payload: { loading: false } });
            return response.data;
        }
    } catch (error) {
        if (error.response.status === 401) {
            toast.error("You don't have permission. Contact Super admin.")
        } else {
            toast.error('please fill all field');
        }
        // toast.error('please fill all field');
        //   dispatch({ type: ALERT, payload: { loading: false } });
        return false;
    }
};
//   export const postCategoryManage = (currentPage, itemsPerPage,obj) => async (dispatch) => {
//     try {

//     //   dispatch({ type: ALERT, payload: { loading: true } });
//       const response = await dispatch(postDataAPI(`category/getcategory?page=${currentPage}&perPage=${itemsPerPage}`, obj))
//       ;

//       if (response) {
//         toast.success('Category Added successfully');
//         // dispatch({ type: ALERT, payload: { loading: false } });
//         return response;
//       }
//     } catch (error) {
//       toast.error('please fill all field');
//     //   dispatch({ type: ALERT, payload: { loading: false } });
//       return false;
//     }
//   };
export const postCategoryManage = (queryParams) => async (dispatch) => {
    try {
        // Assuming queryParams is an object with keys like "currentPage" and "itemsPerPage"
        const queryParamsString = new URLSearchParams(queryParams).toString();

        const response = await dispatch(
            postDataAPI(`blogs-category/getcategory?${queryParamsString}`)
        );

        if (response) {
            // toast.success('Category Added successfully');
            return response;
        }
    } catch (error) {
        //   toast.error('Please fill all fields');
        return false;
    }
};

export const deleleteCategory = (id) => async (dispatch) => {
    try {
        //   dispatch({ type: ALERT, payload: { loading: true } });
        const response = await dispatch(deleteData(`blogs-category/${id}`));
        if (response) {
            toast.success('Category deleted Successfully');
            // dispatch({ type: ALERT, payload: { loading: false } });
            return response.data;
        }
    } catch (error) {
        toast.error('please fill all field');
        //   dispatch({ type: ALERT, payload: { loading: false } });
        return false;
    }
};

export const getCategorys = (id) => async (dispatch) => {
    try {
        //   dispatch({ type: ALERT, payload: { loading: true } });
        const response = await dispatch(getDataAPI(`blogs-category/${id}`));
        if (response) {
            return response;
        }
    } catch (error) {
        // toast.error('please fill all field');
        //   dispatch({ type: ALERT, payload: { loading: false } });
        return false;
    }
};
// http://127.0.0.1:8080/category/64dddda1067e95cd77eb87c1
export const postCategoryUpdate = (id, data) => async (dispatch) => {
    try {
        //   dispatch({ type: ALERT, payload: { loading: true } });
        const response = await dispatch(patchDataApi(`blogs-category/${id}`, data));
        if (response) {
            toast.success('Category updated Successfully');
            // dispatch({ type: ALERT, payload: { loading: false } });
            return response.data;
        }
    } catch (error) {
        console.log(error, " : error");
        if (error.response.status === 401) {
            toast.error("You don't have permission. Contact Super admin.")
        } else {
            toast.error('please fill all field');
        }
        //   dispatch({ type: ALERT, payload: { loading: false } });
        return false;
    }
};

export const getCategory = () => async (dispatch) => {
    try {
        dispatch({
            type: ALERT,
            payload: { loading: true },
        });

        // Include the 'id' as a parameter in the API URL
        const response = await dispatch(getDataAPI(`blogs-category`));

        dispatch({ type: GET_BLOG_CATEGORY, payload: response.data });
        console.log(response, "blogs-category")
    } catch (error) {
        // dispatch({ type: ALERT, payload: { error: error.message } });
        console.warn(error);
        setTimeout(() => {
            dispatch({ type: REMOVE_ALERT });
        }, 5000);
    }
};
